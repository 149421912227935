<template>
  <main class="no-main">
    <div class="ps-breadcrumb">
      <div class="container-fluid-sy">
        <ul class="ps-breadcrumb__list">
          <li class="active"><a href="index.html">Home</a></li>
          <li><a href="javascript:void(0);">Order Tracking</a></li>
        </ul>
      </div>
    </div>
    <section class="section--order-tracking">
      <div class="container">
        <h2 class="page__title"></h2>
        <div class="order-tracking__content">
          <div class="order-tracking__form">
            <form>
              <div class="form-row">
                <div class="col-12">
                  <p>
                    We just sent you an email with instructions to reset your password.
                  </p>
                </div>
                <div class="col-12 text">
                <img  class="okpng mx-auto d-block" src="../../../assets/img/ok.png" alt="ok">
                </div>
                <div class="col-12 form-group-block">
                  <router-link class="btn ps-button" :to="{ name: 'login' }">
                    Login
                  </router-link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<style lang="scss" scoped>
.icon-check{
  width: 400px;
}
.okpng{
  width: 100px;
}
</style>